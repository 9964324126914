import React from 'react';
import s from './LandingSection.module.scss';
import video from 'assets/landing-page/landing-video.mp4';
import poster from 'assets/landing-page/landing-video-poster.jpg';
import BeanButton from 'components/BeanButton/BeanButton';

type Props = {};

const LandingSection = (props: Props) => {
    return (
        <section className={s.container}>
            <video src={video} poster={poster} autoPlay muted loop className={s.video}></video>
            <div className={s.textContainer}>
                <h1 className={s.title}>CS Optical</h1>
                <p className={s.description}>Všetko čo potrebuje Vaša optika</p>

                <BeanButton text='Aktuality' link='/aktuality' color='blue-gradient' />
            </div>
        </section>
    );
};

export default LandingSection;
