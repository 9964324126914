import React, { useEffect, useState } from 'react';
import s from './ServiceRequestForm.module.scss';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Checkbox,
    FormControlLabel,
    Modal,
    TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const URL =
    process.env.NODE_ENV === 'production'
        ? `${process.env.API_URL}/service-request`
        : 'http://localhost:1337/api/service-request';

const ServiceRequestForm = ({ isOpen, onClose }: Props) => {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [deviceSerialNumber, setDeviceSerialNumber] = useState('');
    const [description, setDescription] = useState('');
    const [timeFrom, setTimeFrom] = useState<Dayjs | null>(null);
    const [consentCheckbox, setConsentCheckbox] = useState<boolean>(false);
    const [contactCheckbox, setContactCheckbox] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<string>('Požiadavka o servis odoslaná');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
    const [isAlertActive, setIsAlertActive] = useState<boolean>(false);

    useEffect(() => {
        setTimeFrom(null);
    }, [contactCheckbox]);

    const handleSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();

        onClose();

        const body = {
            email,
            companyName,
            taxNumber,
            companyAddress,
            city,
            phone,
            deviceName,
            deviceSerialNumber,
            description,
            contactTime: dayjs(timeFrom).format('HH:mm'),
            originWebsite: 'CS Optical',
        };

        try {
            onClose();
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.status === 200) {
                setAlertMessage('Požiadavka o servis odoslaná');
                setAlertSeverity('success');
            } else {
                setAlertMessage('Požiadavka o servis sa nepodarilo odoslať');
                setAlertSeverity('error');
            }
        } catch (error) {
            console.error(error);
            setAlertMessage('Požiadavka o servis sa nepodarilo odoslať');
            setAlertSeverity('error');
        } finally {
            setIsAlertActive(true);
            setTimeout((): void => setIsAlertActive(false), 5000);
            resetForm();
        }
    };

    const handleFormExit = (): void => {
        resetForm();
        onClose();
    };

    const resetForm = (): void => {
        setEmail('');
        setCompanyName('');
        setTaxNumber('');
        setCompanyAddress('');
        setCity('');
        setPhone('');
        setDeviceName('');
        setDeviceSerialNumber('');
        setDescription('');
        setTimeFrom(null);
        setConsentCheckbox(false);
    };

    return (
        <>
            <Modal className={s.modal} open={isOpen} onClose={onClose} onBackdropClick={resetForm}>
                <Box component='form' className={s.form} onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
                    <h2 className={s.title}>Požiadavka o servis</h2>

                    <FontAwesomeIcon className={s.closeButton} icon={faClose} onClick={onClose} />

                    <TextField
                        label='E-mail'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                        required
                    />
                    <TextField
                        label='Telefon'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        inputProps={{ inputMode: 'tel', pattern: '\\d{9,}' }}
                        required
                    />

                    <TextField
                        label='Názov firmy'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                    />

                    <TextField label='IČO' value={taxNumber} onChange={(e) => setTaxNumber(e.target.value)} />

                    <TextField label='Mesto' value={city} onChange={(e) => setCity(e.target.value)} required />

                    <TextField
                        label='Adresa firmy'
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        required
                    />

                    <TextField
                        label='Názov zariadenia'
                        value={deviceName}
                        autoComplete='off'
                        onChange={(e) => setDeviceName(e.target.value)}
                        required
                    />

                    <TextField
                        label='Sériové číslo zariadenia'
                        value={deviceSerialNumber}
                        autoComplete='off'
                        onChange={(e) => setDeviceSerialNumber(e.target.value)}
                    />

                    <TextField
                        label='Popis problému'
                        value={description}
                        autoComplete='off'
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        multiline
                        rows={5}
                        className={s.problemDescriptionInput}
                    />

                    <Accordion className={s.accordion} defaultExpanded>
                        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                            Preferovaný čas kontaktu
                        </AccordionSummary>
                        <AccordionDetails className={s.timeInputsContainer}>
                            <FormControlLabel
                                className={`${s.checkboxLabel}, ${s.contactCheckbox}`}
                                control={
                                    <Checkbox
                                        value={contactCheckbox}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setContactCheckbox(e.target.checked)
                                        }
                                    />
                                }
                                label='Kontaktujte nás prosím telefonicky'
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    className={s.timeInput}
                                    label='Čas okolo:'
                                    ampm={false}
                                    value={timeFrom}
                                    onChange={(newTime: Dayjs | null) => {
                                        setTimeFrom(newTime);
                                    }}
                                    renderInput={(params: any) => <TextField autoComplete='off' {...params} />}
                                    minTime={dayjs('2018-01-01T08:00')}
                                    maxTime={dayjs('2018-01-01T16:00')}
                                    minutesStep={15}
                                    disabled={!contactCheckbox}
                                    showToolbar
                                    toolbarTitle={'Napr. 12:00, 14:00'}
                                />
                            </LocalizationProvider>
                            <div>Pracovná doba: 8:00 - 16:00</div>
                        </AccordionDetails>
                    </Accordion>

                    <FormControlLabel
                        className={s.checkboxLabel}
                        control={
                            <Checkbox
                                value={consentCheckbox}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setConsentCheckbox(e.target.checked)
                                }
                                required
                            />
                        }
                        label='Souhlasím se zpracováním mých osobních údajů pro marketingové účely firmou CS Optical a znám svá práva vyplývající ze zákona na ochranu osobních údajů.*'
                    />

                    <Button className={s.submitButton} variant='contained' type={'submit'} disabled={!consentCheckbox}>
                        Odoslať
                    </Button>
                    <Button className={s.submitButton} color='secondary' variant='outlined' onClick={handleFormExit}>
                        Zrušiť
                    </Button>
                </Box>
            </Modal>

            {isAlertActive && (
                <div className={s.alert}>
                    <Alert variant='filled' severity={alertSeverity} onClose={() => setIsAlertActive(false)}>
                        {alertMessage}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default ServiceRequestForm;
