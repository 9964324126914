import React, { useMemo } from 'react';
import s from './Product.module.scss';
import SubPage from 'components/SubPage/SubPage';
import ProductMedia from './ProductMedia/ProductMedia';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductSidebar from './ProductSidebar/ProductSidebar';
import ProductMainDescription from './ProductMainDescription/ProductMainDescription';
import { IProduct } from 'types/types';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import NotFound from 'pages/NotFoundPage/NotFoundPage';

interface Props {
    id: number;
}

const GET_PRODUCT_CATEGORY = gql`
    query GetProductCategory($id: ID!) {
        product(id: $id, locale: "cs") {
            data {
                attributes {
                    name
                    subcategory {
                        data {
                            attributes {
                                category {
                                    data {
                                        attributes {
                                            name
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_PRODUCT_BY_ID = gql`
    query GetProductById($id: ID!) {
        product(id: $id) {
            data {
                id
                attributes {
                    name
                    url
                    short_description
                    description
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    accessories {
                        data {
                            id
                        }
                    }
                    related_products {
                        data {
                            id
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    videos {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    download_files {
                        data {
                            id
                            attributes {
                                url
                                name
                                caption
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Product = ({ id }: Props) => {
    const { loading: queryLoading, data: queryData } = useQuery(GET_PRODUCT_CATEGORY, {
        variables: { id },
    });

    const { loading: loadingProduct, data: dataProduct } = useQuery(GET_PRODUCT_BY_ID, {
        variables: { id },
    });

    const product: IProduct = useMemo(() => dataProduct?.product?.data?.attributes, [dataProduct]);
    console.log(product);
    const hasSidebar = useMemo(
        () => product?.accessories?.data?.length !== 0 || product?.related_products?.data?.length !== 0,
        [product]
    );

    if (loadingProduct || queryLoading) return <Loading />;

    if (product) {
        return (
            <SubPage currentPageName={product.name}>
                <div className={[s.container, hasSidebar ? '' : s.noSidebar].join(' ')}>
                    {(product.accessories.data.length > 0 || product.related_products.data.length > 0) && (
                        <ProductSidebar id={id} />
                    )}

                    <ProductMedia images={product.images} videos={product.videos} />

                    {queryData && (
                        <ProductInfo
                            categoryName={
                                queryData.product.data.attributes.subcategory.data.attributes.category.data.attributes
                                    .name
                            }
                            categoryUrl={
                                queryData.product.data.attributes.subcategory.data.attributes.category.data.attributes
                                    .url
                            }
                            name={product.name}
                            description={product.short_description}
                            downloadFiles={product.download_files}
                        />
                    )}

                    <ProductMainDescription skipSidebar={!hasSidebar} description={product.description} />
                </div>
            </SubPage>
        );
    }

    return <NotFound />;
};

export default Product;
