import React from 'react';
import s from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';

interface Props {}

const Footer = (props: Props) => {
    return (
        <footer className={s.footer}>
            <div className={s.footerTop}>
                <div className={s.linksContainer}>
                    <h4 className={s.linksTitle}>CS Optical</h4>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to='/produkty' className={s.linkAnchor}>
                                Produkty
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/obchod' className={s.linkAnchor}>
                                Obchod
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/servis' className={s.linkAnchor}>
                                Servis
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/o-nas' className={s.linkAnchor}>
                                O nás
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/kontakt' className={s.linkAnchor}>
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={s.linksContainer}>
                    <h4 className={s.linksTitle}>Produkty</h4>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to='/produkty/ocni-diagnostika' className={s.linkAnchor}>
                                Oční diagnostika
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/produkty/optika-2' className={s.linkAnchor}>
                                Optika
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/produkty/refrakce' className={s.linkAnchor}>
                                Refrakce
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to='/produkty/nabytek-pro-ocni-ordinace-a-optiky' className={s.linkAnchor}>
                                Nábytek pro oční ordinace a optiky
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={s.newsletter}>
                    <h4 className={s.newsletterTitle}>Newsletter</h4>
                    <form className={s.newsletterForm}>
                        <TextField
                            id=''
                            onChange={() => null}
                            type='email'
                            label='Tvoj email'
                            variant='filled'
                            color='primary'
                            className={s.newsletterInput}
                            size='small'
                            disabled
                        />
                        <Button variant='contained' size='small' className={s.newsletterSubmit}>
                            {/* Prihlásiť Se */}
                            Už čoskoro!
                        </Button>
                    </form>

                    <SocialMediaLinks />
                </div>
            </div>

            <div className={s.copyright}>
                <span className={s.copyrightText}>
                    Copyright © {new Date().getFullYear()} CS Optical All Rights Reserved
                </span>
            </div>
        </footer>
    );
};

export default Footer;
