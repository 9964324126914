import { gql, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Layout from 'components/Layout/Layout';
import Loading from 'components/Loading/Loading';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import { Link } from 'react-router-dom';
import { ISpecialOffer } from 'types/types';
import SpecialOfferCard from './SpecialOfferCard/SpecialOfferCard';
import s from './SpecialOffersPage.module.scss';

type Props = {};

const GET_SPECIAL_OFFERS = gql`
    query GetSpecialOffers($specialOffer: Boolean!) {
        products(filters: { special_offer: { eq: $specialOffer } }, pagination: { limit: -1 }, locale: "cs") {
            data {
                id
                attributes {
                    name
                    price_normal
                    price_new
                    url
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            id
                            attributes {
                                url
                                category {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SpecialOffersPage = (props: Props) => {
    const { loading, error, data } = useQuery(GET_SPECIAL_OFFERS, {
        variables: { specialOffer: true },
    });

    return (
        <Layout>
            {loading && <Loading />}

            {!loading && !data.products.data.length && (
                <SubPage title='Propagačné akcie'>
                    <div className={s.noOffersContainer}>
                        <h2 className={s.noOffersTitle}>Žiadne propagačné akcie</h2>
                        <Link to='../produkty'>
                            <Button variant='contained'>Produkty</Button>
                        </Link>
                    </div>
                </SubPage>
            )}

            {!loading && !!data.products.data.length && (
                <SubPage title='Propagačné akcie'>
                    <ul className={s.offersList}>
                        {data.products.data.map((product: ISpecialOffer) => (
                            <SpecialOfferCard product={product} />
                        ))}
                    </ul>
                </SubPage>
            )}
        </Layout>
    );
};

export default SpecialOffersPage;
