import React from 'react';
import s from './ContactPage.module.scss';
import Layout from 'components/Layout/Layout';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import SubPage from 'components/SubPage/SubPage';

type Props = {};

const Contact = (props: Props) => {
    return (
        <Layout>
            <SubPage title={'Kontakt'}>
                <div className={s.container}>
                    <iframe
                        className={s.map}
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3637.158123218168!2d18.544293097645255!3d49.863889959051896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4711554239e839b1%3A0xc6c546d688252bec!2zWsOhdm9kbsOtIDU0MC81MSwgTm92w6kgTcSbc3RvLCA3MzUgMDYgS2Fydmluw6EsIEN6ZWNoeQ!5e0!3m2!1spl!2spl!4v1669586631175!5m2!1spl!2spl'
                        title='location-map'
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                    />

                    <ul className={s.contactList}>
                        <h2 className={s.contactListHeader}>Prosím neváhajte nás kontaktovať!</h2>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>Sídlo CS OPTICAL s.r.o.</h3>
                            <p className={s.contactItemDescription}>Závodní 540/51</p>
                            <p className={s.contactItemDescription}>735 06 Karviná - Nové Město</p>
                            <p className={s.contactItemDescription}>Česká Republika</p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>Telefón </h3>
                            <p className={s.contactItemDescription}>+420 596 124 889</p>
                            <p className={s.contactItemDescription}>+420 733 661 621</p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>E-mail</h3>
                            <p className={s.contactItemDescription}>kancelar@csoptical.cz</p>
                            <p className={s.contactItemDescription}>tomas.hana@csoptical.cz</p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>Social Media</h3>
                            <SocialMediaLinks color='black' />
                        </li>
                    </ul>
                </div>
            </SubPage>
        </Layout>
    );
};

export default Contact;
