import React from 'react';
import Layout from 'components/Layout/Layout';
import CategorySelect from './CategorySelect/CategorySelect';
import { Route, Routes } from 'react-router-dom';

import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ProductSelect from './ProductSelect/ProductSelect';
import Product from './Product/Product';

import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';

interface Props {}

const GET_CATEGORIES = gql`
    query GetProductCategories {
        productCategories(pagination: { limit: -1 }, sort: "order:asc", locale: "cs") {
            data {
                id
                attributes {
                    name
                    url
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    subcategories(pagination: { limit: -1 }) {
                        data {
                            id
                            attributes {
                                name
                                url
                                image {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                products(pagination: { limit: -1 }) {
                                    data {
                                        id
                                        attributes {
                                            name
                                            url
                                            short_description
                                            description
                                            is_new
                                            images {
                                                data {
                                                    id
                                                    attributes {
                                                        url
                                                        alternativeText
                                                    }
                                                }
                                            }
                                            accessories {
                                                data {
                                                    id
                                                }
                                            }
                                            related_products {
                                                data {
                                                    id
                                                }
                                            }
                                            manufacturer {
                                                data {
                                                    id
                                                    attributes {
                                                        name
                                                    }
                                                }
                                            }
                                            videos {
                                                data {
                                                    id
                                                    attributes {
                                                        url
                                                        alternativeText
                                                        caption
                                                    }
                                                }
                                            }
                                            download_files {
                                                data {
                                                    id
                                                    attributes {
                                                        url
                                                        name
                                                        caption
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProductsPage = (props: Props) => {
    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_CATEGORIES);

    return (
        <Layout>
            <Routes>
                {queryLoading && <Route path='*' element={<Loading />} />}

                {!queryLoading && (
                    <Route path='/'>
                        {/* Category select */}
                        {!queryLoading && queryData.productCategories.data && (
                            <Route
                                index
                                element={
                                    <CategorySelect
                                        title='Produkty'
                                        description={'Objavte ponuku optických a očných prístrojov'}
                                        categories={queryData.productCategories.data}
                                    />
                                }
                            />
                        )}

                        {/* Subcategory select */}
                        {!queryLoading &&
                            queryData?.productCategories?.data?.map((category: any) => {
                                return (
                                    // Subcategory
                                    <Route path={category.attributes.url} key={category.id}>
                                        <Route
                                            index
                                            element={
                                                <CategorySelect
                                                    title={category.attributes.name}
                                                    categories={category.attributes.subcategories.data}
                                                    showReturnButton
                                                />
                                            }
                                        />

                                        {category.attributes.subcategories.data.map((subcategory: any) => (
                                            <Route
                                                path={`${subcategory.attributes.url}/*`}
                                                element={
                                                    <ProductSelect
                                                        id={subcategory.id}
                                                        title={subcategory.attributes.name}
                                                        description={subcategory.attributes.description}
                                                    />
                                                }
                                                key={subcategory.id}
                                            ></Route>
                                        ))}
                                    </Route>
                                );
                            })}
                    </Route>
                )}

                {!queryLoading && <Route path='*' element={<NotFoundPage />} />}
            </Routes>
        </Layout>
    );
};

export default ProductsPage;
