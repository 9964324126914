import React, { useMemo, useState } from 'react';
import s from './ProductSelect.module.scss';
import ProductCard from './ProductCard/ProductCard';
import SubPage from 'components/SubPage/SubPage';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addStrapiUrl } from 'utils/strapiUtils';
import BeanButton from 'components/BeanButton/BeanButton';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import { Route, Routes } from 'react-router-dom';
import Product from '../Product/Product';
import { IManufacturer } from 'types/types';

export interface IProduct {
    id: number;
    attributes: {
        name: string;
        short_description: string;
        description: string;
        is_new: boolean;
        url: string;
        images: {
            id: string;
            data: {
                attributes: {
                    url: string;
                };
            }[];
        };
        manufacturer: IManufacturer;
    };
}

interface Props {
    title: string;
    description: string;
    id: number;
}

const GET_PRODUCTS_BY_SUBCATEGORY_ID = gql`
    query GetProductsBySubcategoryId($id: ID!, $manufacturerId: ID) {
        products(
            filters: { and: [{ subcategory: { id: { eq: $id } } }, { manufacturer: { id: { eq: $manufacturerId } } }] }
            locale: "cs"
        ) {
            data {
                id
                attributes {
                    name
                    url
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_MANUFACTURERS = gql`
    query GetManufacturers($id: ID!) {
        manufacturers(filters: { products: { subcategory: { id: { eq: $id } } } }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

const ProductSelect = ({ title, description, id }: Props) => {
    const [selectedManufacturerId, setSelectedManufacturerId] = useState<string>('unset');

    const { data, loading } = useQuery(GET_PRODUCTS_BY_SUBCATEGORY_ID, {
        variables: { id, manufacturerId: selectedManufacturerId === 'unset' ? undefined : selectedManufacturerId },
    });

    const { loading: manufLoading, data: manufData } = useQuery(GET_MANUFACTURERS, { variables: { id } });

    const products: IProduct[] = useMemo(() => data?.products?.data, [data]);
    const manufacturers: IManufacturer[] = useMemo(() => manufData?.manufacturers?.data, [manufData]);

    return (
        <Routes>
            {(loading || manufLoading) && <Route path='*' element={<Loading />} />}
            {!(loading || manufLoading) && data && (
                <Route>
                    <Route
                        index
                        element={
                            <SubPage>
                                <div className={s.container}>
                                    <div className={s.pageDescription}>
                                        <h1 className={s.title}>{title}</h1>
                                        {description && <p className={s.description}>{description}</p>}
                                    </div>

                                    <div className={s.filtersContainer}>
                                        <p className={s.displayedProductsNumber}>
                                            Zobrazuje sa {`${products.length} z ${products.length} `}
                                            produktov
                                        </p>

                                        <FormControl disabled={manufacturers?.length === 0} sx={{ minWidth: 200 }}>
                                            {manufacturers?.length !== 0 && (
                                                <>
                                                    <InputLabel id='manufacturer-filter-select-label'>
                                                        Výrobca
                                                    </InputLabel>
                                                    <Select
                                                        labelId='manufacturer-filter-select-label'
                                                        id='manufacturer-filter-select'
                                                        label='Výrobca'
                                                        value={selectedManufacturerId}
                                                        onChange={(event) =>
                                                            setSelectedManufacturerId(event.target.value)
                                                        }
                                                    >
                                                        <MenuItem value={'unset'}>Všetky</MenuItem>
                                                        {manufacturers.map((manuf) => (
                                                            <MenuItem
                                                                value={manuf.id}
                                                                key={manuf.id + manuf.attributes.name}
                                                            >
                                                                {manuf.attributes.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                        </FormControl>
                                    </div>

                                    <ul className={s.productsList}>
                                        {products.map((product) => (
                                            <ProductCard
                                                title={product.attributes.name}
                                                image={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                                                url={product.attributes.url}
                                                isOfferNew={product.attributes.is_new}
                                                key={product.id}
                                            />
                                        ))}
                                    </ul>

                                    <div className={s.returnButtonContainer}>
                                        <BeanButton
                                            text='Návrat'
                                            link='..'
                                            color='blue'
                                            variant='outlined'
                                            iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                                        />
                                    </div>
                                </div>
                            </SubPage>
                        }
                    />
                    {products.map((product: IProduct) => {
                        return (
                            <Route
                                path={product.attributes.url}
                                element={<Product id={product.id} />}
                                key={product.attributes.url}
                            />
                        );
                    })}
                </Route>
            )}
        </Routes>
    );
};

export default ProductSelect;
