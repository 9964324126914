import React from 'react';
import s from './ProductSidebar.module.scss';
import { gql, useQuery } from '@apollo/client';
import FeaturedProducts from './FeaturedProducts/FeaturedProducts';

interface Props {
    id: number;
}

const GET_FEATURED_PRODUCTS = gql`
    query GetFeaturedProducts($id: ID!) {
        product(id: $id, pagination: { limit: -1 }, locale: "cs") {
            data {
                attributes {
                    accessories {
                        data {
                            id
                            attributes {
                                name
                                url
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    related_products {
                        data {
                            id
                            attributes {
                                url
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProductSidebar = ({ id }: Props) => {
    const {
        loading: queryLoading,
        error: queryError,
        data: queryData,
    } = useQuery(GET_FEATURED_PRODUCTS, {
        variables: { id },
    });

    if (queryLoading) return null;

    return (
        <div className={s.container}>
            {queryData.product.data.attributes.accessories.data.length > 0 && (
                <FeaturedProducts title='Akcesoria' products={queryData.product.data.attributes.accessories} />
            )}

            {queryData.product.data.attributes.related_products.data.length > 0 && (
                <FeaturedProducts
                    title='Súvisiace zariadenia'
                    products={queryData.product.data.attributes.related_products}
                />
            )}
        </div>
    );
};

export default ProductSidebar;
