import React from 'react';
import s from './CategorySelect.module.scss';
import CategoryCard from './CategoryCard/CategoryCard';
import SubPage from 'components/SubPage/SubPage';
import { ICategory } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import backgroundImg from 'assets/subpage-images/products.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';

interface Props {
    title: string;
    description?: string;
    categories: ICategory[];
    showReturnButton?: boolean;
}

const CategoriesSection = ({ title, description, categories, showReturnButton = false }: Props) => {
    return (
        <SubPage title={title} description={description} image={backgroundImg}>
            <div className={s.container}>
                <div className={s.categoriesList}>
                    {categories &&
                        categories.map(({ attributes }) => {
                            if (attributes.name && attributes.image && attributes.url) {
                                return (
                                    <CategoryCard
                                        category={attributes.name}
                                        image={addStrapiUrl(attributes?.image?.data?.attributes?.url)}
                                        url={attributes.url}
                                        key={attributes.url}
                                    />
                                );
                            }

                            return null;
                        })}
                </div>

                {showReturnButton && (
                    <div className={s.returnButtonContainer}>
                        <BeanButton
                            text='Návrat'
                            link='..'
                            color='blue'
                            variant='outlined'
                            iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                        />
                    </div>
                )}
            </div>
        </SubPage>
    );
};

export default CategoriesSection;
