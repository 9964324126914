import React, { useState } from 'react';
import s from './QuestionForm.module.scss';
import { Alert, Box, Button, Checkbox, FormControlLabel, Modal, TextField } from '@mui/material';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const URL =
    process.env.NODE_ENV === 'production'
        ? `${process.env.API_URL}/product/ask-question`
        : 'http://localhost:1337/api/product/ask-question';

const QuestionForm = ({ isOpen, onClose }: Props) => {
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [question, setQuestion] = useState<string>('');

    const [alertMessage, setAlertMessage] = useState<string>('Dopyt odoslaný');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
    const [isAlertActive, setIsAlertActive] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const body = {
            name,
            surname,
            phone,
            email,
            company,
            question,
            originWebsite: 'CS Optical',
        };

        try {
            onClose();
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.status === 200) {
                setAlertMessage('Dopyt odoslaný');
                setAlertSeverity('success');
            } else {
                setAlertMessage('Dopyt sa nepodarilo odoslať');
                setAlertSeverity('error');
            }
        } catch (error) {
            console.error(error);
            setAlertMessage('Dopyt sa nepodarilo odoslať');
            setAlertSeverity('error');
        } finally {
            setIsAlertActive(true);
            setTimeout(() => setIsAlertActive(false), 5000);
            resetForm();
        }
    };

    const handleFormClose = (): void => {
        onClose();
        resetForm();
    };

    const resetForm = (): void => {
        setName('');
        setSurname('');
        setPhone('');
        setEmail('');
        setCompany('');
        setQuestion('');
    };

    return (
        <>
            <Modal className={s.modal} open={isOpen} onClose={handleFormClose}>
                <div className={s.formContainer}>
                    <h5 className={s.title}>Položte otázku</h5>

                    <Box component='form' className={s.form} onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
                        <TextField
                            id='name'
                            label='Meno'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />

                        <TextField
                            id='surname'
                            label='Priezvisko'
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />

                        <TextField
                            id='email'
                            label='E-mail'
                            type={'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <TextField
                            id='phone'
                            label='Telefónne číslo'
                            value={phone}
                            inputProps={{ inputMode: 'tel', pattern: '\\d{9,}' }}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />

                        <TextField
                            className={s.companyInput}
                            id='company'
                            label='Firma'
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />

                        <TextField
                            id='question'
                            className={s.questionInput}
                            multiline
                            minRows={3}
                            maxRows={6}
                            label='Podrobnosti o dopyte'
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            required
                        />

                        <FormControlLabel
                            className={s.checkboxLabel}
                            control={<Checkbox required />}
                            label='Souhlasím se zpracováním mých osobních údajů pro marketingové účely firmou CS Optical a znám svá práva vyplývající ze zákona na ochranu osobních údajů.*'
                        />

                        <Button className={s.submitButton} type={'submit'} variant='contained'>
                            Odoslať
                        </Button>
                        <Button
                            className={s.submitButton}
                            color='secondary'
                            variant='outlined'
                            onClick={handleFormClose}
                        >
                            Zrušiť
                        </Button>
                    </Box>
                </div>
            </Modal>

            {isAlertActive && (
                <div className={s.alert}>
                    <Alert variant='filled' severity={alertSeverity} onClose={() => setIsAlertActive(false)}>
                        {alertMessage}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default QuestionForm;
