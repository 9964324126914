import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import dayjs from 'dayjs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { IEvent } from 'types/types';
import 'scss/strapiRichText.scss';
import s from './Event.module.scss';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import SubPage from 'components/SubPage/SubPage';
import { Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import RichTextDisplay from 'components/RichTextDisplay/RichTextDisplay';

type Props = {};

const GET_EVENTS = gql`
    query GetEvent($id: ID!) {
        event(id: $id, locale: "cs") {
            data {
                id
                attributes {
                    title
                    description
                    date_start
                    date_end
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    content
                }
            }
        }
    }
`;

const Event = (props: Props) => {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET_EVENTS, {
        variables: { id: id },
    });

    const event: IEvent = data?.event.data;

    if (loading) return <Loading />;

    if (error || (!loading && !event)) return <NotFound />;

    return (
        <SubPage currentPageName={event.attributes.title}>
            <div className={s.container}>
                <div className={s.topContainer}>
                    <h1 className={s.title}>{event.attributes.title}</h1>
                    {event.attributes.date_start && (
                        <div className={s.date}>
                            <Chip
                                label={`${dayjs(event.attributes.date_start).format('DD.MM.YYYY')} ${
                                    event.attributes.date_end
                                        ? '- ' + dayjs(event.attributes.date_end).format('DD.MM.YYYY')
                                        : ''
                                }`}
                                icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                                sx={{ padding: '.5rem' }}
                            />
                        </div>
                    )}
                </div>
                <RichTextDisplay html={event.attributes.content} />
            </div>
        </SubPage>
    );
};

export default Event;
